export default({
    state:{
        questionShow:false
    },
    mutations:{
        showQuestion(state){
            state.questionShow = true
        },
        hideQuestion(state){
            state.questionShow = false
        }
    },
    actions:{
    },
    getters:{
    }
})