export default({
    state: {
        lookChapterScope:{}
    },
    getters: {
    },
    mutations: {
        changeLookChapterScope(state,scope){
            state.lookChapterScope = scope
        }
    },
    actions: {
    }
})