import { createStore } from 'vuex'
import onlineCourse from './coursestore/onlineCourse'
import MindEvaluationStore from './homestore/MindEvaluationStore'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    onlineCourse,
    MindEvaluationStore

  }
})
