<template>
    <link rel="stylesheet" href="https://at.alicdn.com/t/c/font_4674400_rte3rrwcel.css?spm=a313x.manage_type_myprojects.i1.9.203f3a81SrJzrJ&file=font_4674400_rte3rrwcel.css">
        <el-scrollbar class="col">
            <el-menu active-text-color="#00aaff" class="el-menu-vertical-demo" background-color="#304156"
                text-color="#ffffff" :default-active="route.path" :router="true">

                <el-sub-menu index="/">
                    <template #title>
                        <el-icon>
                            <i class="iconfont icon-shouye"></i>
                        </el-icon>
                        <span>首页</span>
                    </template>

                    <el-menu-item index="/home/swiper" >
                        <el-icon><i class="iconfont icon-shouye"></i></el-icon>
                        <span>轮播图管理</span>
                    </el-menu-item>
                    <el-menu-item index="/home/mind" >
                        <el-icon><i class="iconfont icon-shouye"></i></el-icon>
                        <span>心理测评管理</span>
                    </el-menu-item>
                    <el-menu-item index="/home/xinlidayi" >
                        <el-icon><i class="iconfont icon-shouye"></i></el-icon>
                        <span>心理答疑管理</span>
                    </el-menu-item>
                    <el-menu-item index="/home/mindknowledge" >
                        <el-icon><i class="iconfont icon-shouye"></i></el-icon>
                        <span>心理知识管理</span>
                    </el-menu-item>
                    <el-menu-item index="/home/fm" >
                        <el-icon><i class="iconfont icon-shouye"></i></el-icon>
                        <span>FM管理</span>
                    </el-menu-item>
                    <el-menu-item index="/home/gongyi" >
                        <el-icon><i class="iconfont icon-shouye"></i></el-icon>
                        <span>公益中心管理</span>
                    </el-menu-item>
                    <el-menu-item index="/home/wenzhang" >
                        <el-icon><i class="iconfont icon-shouye"></i></el-icon>
                        <span>文章管理</span>
                    </el-menu-item>

                    
                </el-sub-menu>


                <el-sub-menu index="/bangqukuaidi">
                    <template #title>
                        <el-icon>
                            <i class="iconfont icon-zixun"></i>
                        </el-icon>
                        <span>咨询</span></template>

                    <el-menu-item index="/zixun/teacher" >
                        <el-icon><i class="iconfont icon-zixun"></i></el-icon>
                        <span>咨询师管理</span>
                    </el-menu-item>
                    <el-menu-item index="/zixun/shaixuan" >
                        <el-icon><i class="iconfont icon-zixun"></i></el-icon>
                        <span>筛选条件管理</span>
                    </el-menu-item>
                    
                </el-sub-menu>

                <el-sub-menu index="/course">
                    <template #title>
                        <el-icon>
                            <i class="iconfont icon-kecheng"></i>
                        </el-icon>
                        <span>课程</span></template>

                    <el-menu-item index="/course/c_swiper">
                        <el-icon><i class="iconfont icon-kecheng"></i></el-icon>
                        <span>轮播图管理</span>
                    </el-menu-item>
                    <el-menu-item index="/course/online">
                        <el-icon><i class="iconfont icon-kecheng"></i></el-icon>
                        <span>在线课程管理</span>
                    </el-menu-item>
                    
                </el-sub-menu>

                <el-sub-menu index="4">
                    <template #title>
                        <el-icon>
                            <i class="iconfont icon-wode"></i>
                        </el-icon>
                        <span>我的</span></template>

                    <el-menu-item index="4-1">
                        <el-icon><i class="iconfont icon-wode"></i></el-icon>
                        <span>用户状态管理</span>
                    </el-menu-item>
                    <el-menu-item index="4-1">
                        <el-icon><i class="iconfont icon-wode"></i></el-icon>
                        <span>用户密码管理</span>
                    </el-menu-item>
                </el-sub-menu>

            </el-menu>
        </el-scrollbar>
   
</template>

<script setup>
import { useRoute } from 'vue-router';
const route = useRoute();

</script>

<style scoped lang="less">
.el-menu{
    border: none;
}
.col{
    width: 100%;
}
</style>
