import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../views/LayoutPage.vue'

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: {name: 'home'},
    children:[
      {
        path: 'home',
        name: 'home',
        redirect: {name: 'swiper'},
        children: [
          {
            path: 'swiper',
            name: 'swiper',
            component: () => import('../views/homepage/swiper/SwiperPage.vue')
          },
          {
            path: 'mind',
            name: 'mind',
            component: () => import('../views/homepage/mindevaluation/MindEvaluationPage.vue')
          },
          {
            path: 'mindknowledge',
            name: 'mindknowledge',
            component: () => import('@/views/homepage/mindknowledge/MindKnowledgePage.vue')
          },
          {
            path:"fm",
            name:"fm",
            component: ()=>import("@/views/homepage/fm/FmPage.vue")
          },
          {
            path:"gongyi",
            name:"gongyi",
            component: ()=>import("@/views/homepage/gongyi/GongyiPage.vue")
          },
          {
            path:"wenzhang",
            name:"wenzhang",
            component: ()=>import("@/views/homepage/wenzhang/WenzhangPage.vue")
          },
          {
            path:"xinlidayi",
            name:"xinlidayi",
            component: ()=>import("@/views/homepage/xinlidayi/XinliDayiPage.vue")
          },
          
        ]
      },
      {
        path: 'zixun',
        name: 'zixun',
        redirect:{name:'teacher'},
        children:[
          {
            path: 'teacher',
            name: 'teacher',
            component: () => import('../views/consultpage/TeacherPage.vue')
          },
          {
            path:'shaixuan',
            name:'shaixuan',
            component:()=>import('../views/consultpage/ShaixuanPage.vue')
          }
          
        ]
      },
      {
        path: 'course',
        name: 'course',
        redirect: {name: 'c_swiper'},
        children: [
          {
            path:'c_swiper',
            name:'c_swiper',
            component:() => import('../views/coursepage/swiperPage.vue')
          },
          {
            path: 'online',
            name: 'online',
            component: () => import('../views/coursepage/onlinePage.vue')
          }
        ]
      }
      
    ]
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
